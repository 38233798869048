import React, {useRef, useEffect} from "react"
import Modal from "react-bootstrap/Modal"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumbs_Insights from "../components/Breadcrumbs/Breadcrumbs_Insights"

import MemberBlock from "../components/MemberBlock/MemberBlock"
import CustomerReviews from "../components/CustomerReviews/CustomerReviews"
import PropertyPortfolio from "../components/PropertyPortfolio/PropertyPortfolio"

// import {PeopleDetails} from "../queries/common_use_query"
import MemberForm from "../components/forms/member-form"
import LoaderLogo from '../images/loader_logo_new.svg';

import "../scss/custom.scss"
import VideoSlider from "../components/VideoSlider/VideoSlider";
import TeamDetailReview from "../components/TeamDetailReview/TeamDetailReview";
import LatestBlogsComponent from "../components/Home/LatestBlogs/LatestBlogs";
import { removeDataAttributes } from "../comQueryStructure";
import { graphql } from "gatsby";

function PeoplesTemplate (props){
    const myRef = useRef(null)

    let path_name = (props.location.pathname).split('/');
    let get_url = path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2];

    // const {loading, error, data} = PeopleDetails(get_url);
    const peopledata = removeDataAttributes(props?.data).glstrapi
    const people_data = peopledata?.peoples
    
    //console.log("get_url",get_url);
    //console.log("insightdata",people_data);

    const scrollTopropertyDetails = () => window.scroll ({
        top: myRef.current.offsetTop - 100,
        left: 0,
        behavior: 'smooth'
    });

    var meta_title = '', meta_desc = '';

    if(people_data && people_data.length > 0){
      meta_title = people_data[0].Name+", "+people_data[0].Designation+" | Propert Experts";
      meta_desc = "Get to know the "+people_data[0].Name+", "+people_data[0].Designation+" at The Pearl Gates, Qatar, committed to helping you select your dream home or investment. Your real estate experts.";
    }
    

    return (
    <>
      <Layout Layout={'Without_Banner'} classNames="Without_Banner">

          {/* ======= Breadcrumbs ====== */}
          {/* <Breadcrumbs_Insights page_name="People" Heading={data && people_data[0].Name} location={props.location}/> */}
          {
            
            people_data && people_data.length > 0 && <>
                <SEO title={meta_title} description={meta_desc} />

                <Helmet/>

                <div className="page-content people_details">
                  <div className="container ">
                    <div className="row">
                      <div className="video-detail-template peolpe_details">
                        <Breadcrumbs_Insights page_name="People" Heading={people_data[0].Name} location={props.location} />
                      </div>
                    </div>
                  </div>

                  <MemberBlock data={people_data[0]} handleClick={scrollTopropertyDetails} />

                  {/* ======= Customer Review ====== */}
                  {/* <div ref={myRef}></div>
                  {data.peoples[0].Customer_Reviews.length > 0 && 
                  <CustomerReviews data={data.peoples[0].Customer_Reviews} /> } */}

                  {/* ======= My Portfolio ====== */}
                  {people_data[0].Category !== "Support" && (
                    <PropertyPortfolio people_name={people_data[0].Name} />
                  )
                  }
                  {
                    people_data[0].My_Property_Videos !== null &&
                    <VideoSlider data={people_data[0].My_Property_Videos} />
                  }
                  <TeamDetailReview people_name={people_data[0].Name} />
                  { people_data[0]?.Team_Insights?.Show_Insights === true &&
                  <LatestBlogsComponent  isPeoplePage={true}/>}
                </div>
                
            </>
          }
      </Layout>
    </>
  )
}

export const data = graphql`
query PeopleDeatils($slug: String){
  glstrapi {
    peoples(filters:{URL:{eq: $slug}}){
      data {
        id
        attributes {
          Name
          URL
          Meta_Title
          Meta_Description
          Highlight_Text
          About
          Rating
          Areas_of_Speciality
          Designation
          Category
          Phone
          Email
          Linked_In
          WhatsApp
          Calendly_Link
          Team_Insights {
            Show_Insights
            Filter_by_Tag
          }
          Embed_Video_URL
          Image{
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Tile_Image{
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Customer_Reviews{
            data {
              id
              attributes {
                Name
                Embed_Video_URL
                Content
                Category
                Rating
                
                imagetransforms
                ggfx_results {
                  data {
                    attributes {
                      src_cftle
                      transforms 
                    }
                  }
                }
              }
            }
          }
          My_Property_Videos {
            Description
            Title
            Videos_List {
              Sort
              Tile_Image {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              Video_Orignal {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              Video_Title
              Video_Type
              Video_Url
            }
          }
        }
      }
    }
  }
}
`

export default PeoplesTemplate