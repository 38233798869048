import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils";
import { Button, Container, Form, Nav, Tab } from "react-bootstrap"
import Slider from "react-slick"
// Images

import PortfolioImgMobile from "../../images/static/portfolio_img_mobile.jpg"
import PortfolioImgTablet from "../../images/static/portfolio_img_tablet.jpg"

import PortfolioImgMobile1 from "../../images/static/portfolio_img_mobile1.jpg"
import PortfolioImgTablet1 from "../../images/static/portfolio_img_tablet1.jpg"

import PortfolioImgMobile2 from "../../images/static/portfolio_img_mobile2.jpg"
import PortfolioImgTablet2 from "../../images/static/portfolio_img_tablet2.jpg"

import ContactCard from "../Home/ContactCard/ContactCard"
import "./PropertyPortfolio.scss"
// markup

import { addCommas, stb_sale_link, stb_rent_link, stb_sold_link, stb_let_link } from "../common/utils"
import axios from "axios"

import {PortfolioProperties} from "../../queries/common_use_query"

const PropertyPortfolio = (props) => {

    const [propItems, setPropItems] = useState([]);
    const [keys, setKey] = useState("");
    //var stb_link = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/";

    const {loading, error, data} = PortfolioProperties(props.people_name);

    // const getitems = async url => {
    //     setPropItems([]);
    //     try {
    //         const { data } = await axios.get(url, {})
    //         // 
    //         // console.log("PropertySimilar", data)
    //         setPropItems(data)
    //         //return data;
    //     } catch (error) {
    //         // console.error(error)
    //     }
    // }

    // useEffect(() => {
    //     //
    //     let property_sale = getitems(stb_sale_link);
    // }, []);

    // useEffect(() => {
    //     //
    //     props.StbList && props.StbList.length > 0 && getitems(stb_link+props.StbList[0].Stb_List_Item)
    // }, [props.StbList]);

    let settings = {
      dots: true,
      arrows: false,
      infinite: data && data[keys]?.length > 3 ? true : false,
      speed: 800,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: false,
      responsive: [
      {
          breakpoint: 1200,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          },
      },
      {
          breakpoint: 992,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: data && data[keys]?.length > 2 ? true : false,
          dots: true,
          },
      },
      {
          breakpoint: 767,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: data && data[keys]?.length > 1 ? true : false,
          dots: true,
          },
      },
      ],
  } 

  var negoPropItem =  data && Object.keys(data).filter(key => data[key]?.data.length>0);
  const handleFunction = (i) => {
    setKey(i)
  }
  return (
  <React.Fragment>
    { negoPropItem && negoPropItem.length > 0 && 
      <div className="market-analysis property-portfolio">
        {/* <span className="bg-curve"><img src={bgCurve} alt="bg-img" /></span> */}
        <Container>
          <div className="discover-range d-md-block">
            <h2>My Property Portfolio </h2>
             <div className="animated">        
              <Tab.Container id="left-tabs-example" defaultActiveKey={negoPropItem[0]}>

                <Nav variant="pills">              
                  <div className="pills-wrap">   
                        {
                          negoPropItem.map((key) => {
                            var tab_name = '';
                            if(key === "propertiesSale"){
                              tab_name = "Properties for Sale";
                            } else if(key === "propertiesRent"){
                              tab_name = "Properties for Rent";
                            } else if(key === "propertiesLet"){
                              tab_name = "Let Properties";
                            } else if(key === "propertiesSold"){
                              tab_name = "Sold Properties";
                            }
                            return(
                              <Nav.Item>
                                <Nav.Link eventKey={key} onClick={() => handleFunction(key)}>{tab_name}</Nav.Link>
                              </Nav.Item>
                            )
                          })
                        } 
                            
                  </div>              
                </Nav>            

                <Tab.Content>
                  {
                    negoPropItem.map((key) => {
                      return(
                        <Tab.Pane eventKey={key} className="text-center">
                          {
                              data[key]?.data && data[key]?.data.length > 0 ? (
                                  <Slider {...settings} >
                                      {
                                        data[key]?.data.map((item, index) => {

                                            var detail_link = "properties-for-sale";
                                            
                                            if(item?.attributes.status === "for sale"){
                                              detail_link = "properties-for-sale";
                                            } else if(item?.attributes.status === "for rent"){
                                              detail_link = "properties-for-rent";
                                            } else if(item?.attributes.status === "sold"){
                                              detail_link = "properties-sold";
                                            } else if(item?.attributes.status === "rented"){
                                              detail_link = "properties-let";
                                            }

                                            return (
                                                <ContactCard
                                                    cardImg={item?.attributes.images.length > 0 && item?.attributes.images?.[0]}
                                                    cardTitle={item?.attributes.title+' in '+item?.attributes.display_address}
                                                    date={`${addCommas(item?.attributes.price)} QR`}
                                                    cardTag={""}
                                                    cardUrl={`/${detail_link}/${item?.attributes.slug}-${item?.id}`}
                                                />
                                            )
                                        })
                                      }
                                  </Slider>
                              ) : <span className="text-center col-12 mt-5 mb-5"></span>
                          }
                        </Tab.Pane>
                      )
                    })
                  }
                </Tab.Content>

            </Tab.Container>
             </div>
          </div>
        </Container>
      </div>
    }
    
  </React.Fragment>
  )
}

export default PropertyPortfolio
